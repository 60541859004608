<template>
    <div>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
            <div class="page_content">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">关键词</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.key_word" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">分类</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.cate_name" clearable placeholder="请选择">
                                    <el-option v-for="item in classifyOptions" :key="item" :value="item"> </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item v-show="form.cate_name == '热搜词'">
                        <template slot="label">
                            <div class="form_label">类型</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.type" clearable placeholder="请选择">
                                    <el-option
                                        v-for="item in typeOptions"
                                        :key="item.value"
                                        :value="item.value"
                                        :label="item.label"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>
            <div class="dialog_handle">
                <button class="button" @click="addInfo">提交</button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import axios from "axios";
import qs from "qs";

export default {
    props: ["amendId", "amendTitle"],
    data() {
        return {
            dialogVisible: true,
            form: {},
            typeOptions: [
                {
                    label: "共享",
                    value: 1,
                },
                {
                    label: "搜索",
                    value: 2,
                },
                {
                    label: "底部",
                    value: 3,
                },
            ],
            // 分类名
            classifyOptions: ["客户案例", "热搜词"],
        };
    },
    mounted() {
        this.getPageInfo();
    },
    computed: {
        dialogTitle() {
            return this.amendTitle;
        },
    },
    methods: {
        // 页面数据
        getPageInfo() {
            axios
                .post(
                    "/api/key_word/review",
                    qs.stringify({
                        id: this.amendId,
                    })
                )
                .then(res => {
                    this.form = res.data;
                });
        },

        // 提交
        addInfo() {
            axios
                .post(
                    "/api/Key_word/editOrAdd",
                    qs.stringify({
                        ...this.form,
                    })
                )
                .then(res => {
                    if (res.data.code != 200) {
                        this.$message.error(res.data.msg);
                    } else {
                        this.$message.success(res.data.msg);
                    }
                    this.$emit("closePage", 0);
                });
        },

        // 关闭弹窗
        handleClose() {
            this.$emit("closePage", 0);
            // this.$confirm("确认关闭？")
            //     .then(_ => {
            //     })
            //     .catch(_ => {});
        },
    },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog {
    margin-right: 100px !important;
    margin-top: 100px !important;
}

/deep/ .el-dialog__body {
    padding: 20px !important;
}
.page_content {
    width: 90%;

    .form_label {
        width: 80px;
        height: 40px;
        line-height: 43px;
        text-align: center;
        color: white;
        font-size: 16px;
        border: 0;
        border-radius: 20px 0 0 20px;
        background: linear-gradient(to right, #da8cff, #9a55ff);
    }

    .form_value {
        .form_input {
            height: 40px;
            width: 100%;
            padding-left: 15px;
            border: 1px solid #ebedf2;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 0 13px 13px 0;
            &:focus {
                // outline: 1px solid skyblue;
            }
        }
    }
}
.dialog_handle {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        margin-right: 10px;
    }
}
</style>
